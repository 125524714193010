
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({components : {}})
export default class BaseDialog extends Vue {
    @Prop({ required : true, default: false}) readonly value!: boolean
    @Prop({ required : true }) readonly title!: string
    @Prop({ default: "primary"}) readonly titleColor!: string
    @Prop({ default: true }) readonly showClose!: boolean
    @Prop({ default: null }) readonly error!: string | Error
    @Prop({ default: true }) readonly scrollable!: boolean

    isScrolled : boolean = false
    
    onScroll(e : any) {
        this.isScrolled = e.target.scrollTop > 0
    }

}
